<template>
    <div class="team-groups__wrp">
        <table>
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Access
                    </th>
                    <th>
                        
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="item in groupStore.groups"
                        :key="item.uk"
                    >
                        <td>{{ item.t }}</td>
                        <td @click="showEditUsersGroupMethod(item)">
                            <span>{{ item.mn }}</span>
                            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.63774 4.10521C3.83781 4.29826 4.16219 4.29826 4.36226 4.10521L7.22392 1.3439C7.54666 1.03248 7.31808 0.5 6.86166 0.5H1.13834C0.681918 0.5 0.453341 1.03248 0.776079 1.3439L3.63774 4.10521Z" fill="black"/>
                            </svg>
                        </td>
                        <td @click="showEditModalMethod(item)">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 5L1.5 12.5V16.5H5.5L13 9M9 5L12.5 1.5L16.5 5.5L13 9M9 5L13 9" stroke="#BABCBF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </td>
                        <td @click="archiveGroup(item)">
                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 5.99984C17.0523 5.99984 17.5 5.55212 17.5 4.99984C17.5 4.44755 17.0523 3.99984 16.5 3.99984V5.99984ZM1.5 3.99984C0.947715 3.99984 0.5 4.44755 0.5 4.99984C0.5 5.55212 0.947715 5.99984 1.5 5.99984V3.99984ZM12.3333 4.99984V5.99984H13.3333V4.99984H12.3333ZM5.66667 4.99984H4.66667V5.99984H5.66667V4.99984ZM3.16667 5.99984H14.8333V3.99984H3.16667V5.99984ZM13.8333 4.99984V16.6665H15.8333V4.99984H13.8333ZM13.1667 17.3332H4.83333V19.3332H13.1667V17.3332ZM4.16667 16.6665V4.99984H2.16667V16.6665H4.16667ZM14.8333 5.99984H16.5V3.99984H14.8333V5.99984ZM3.16667 3.99984H1.5V5.99984H3.16667V3.99984ZM4.83333 17.3332C4.46514 17.3332 4.16667 17.0347 4.16667 16.6665H2.16667C2.16667 18.1393 3.36057 19.3332 4.83333 19.3332V17.3332ZM13.8333 16.6665C13.8333 17.0347 13.5349 17.3332 13.1667 17.3332V19.3332C14.6394 19.3332 15.8333 18.1393 15.8333 16.6665H13.8333ZM7.33333 2.6665H10.6667V0.666504H7.33333V2.6665ZM11.3333 3.33317V4.99984H13.3333V3.33317H11.3333ZM12.3333 3.99984H5.66667V5.99984H12.3333V3.99984ZM6.66667 4.99984V3.33317H4.66667V4.99984H6.66667ZM10.6667 2.6665C11.0349 2.6665 11.3333 2.96498 11.3333 3.33317H13.3333C13.3333 1.86041 12.1394 0.666504 10.6667 0.666504V2.6665ZM7.33333 0.666504C5.86057 0.666504 4.66667 1.86041 4.66667 3.33317H6.66667C6.66667 2.96498 6.96514 2.6665 7.33333 2.6665V0.666504Z" fill="#606569"/>
                                <path d="M11.9737 8.80957C11.9737 8.25729 11.526 7.80957 10.9737 7.80957C10.4214 7.80957 9.97368 8.25729 9.97368 8.80957H11.9737ZM9.97368 14.3651C9.97368 14.9174 10.4214 15.3651 10.9737 15.3651C11.526 15.3651 11.9737 14.9174 11.9737 14.3651H9.97368ZM8.02631 8.80957C8.02631 8.25729 7.57859 7.80957 7.02631 7.80957C6.47402 7.80957 6.02631 8.25729 6.02631 8.80957H8.02631ZM6.02631 14.3651C6.02631 14.9174 6.47402 15.3651 7.02631 15.3651C7.57859 15.3651 8.02631 14.9174 8.02631 14.3651H6.02631ZM9.97368 8.80957V14.3651H11.9737V8.80957H9.97368ZM6.02631 8.80957V14.3651H8.02631V8.80957H6.02631Z" fill="#BABCBF"/>
                            </svg>
                        </td>
                        <td>
                            <EditUsersGroup :groupRow="groupUser" v-if="item.showEditUsersGroup" @close="showEditUsersGroup = false, item.showEditUsersGroup = false"/>
                        </td>
                    </tr>
                </tbody>
        </table>
        <EditGroupModal :groupRow="groupRow"  v-if="showEditGrouptModal" @close="showEditGrouptModal = false"/>
        <div class="bg" v-if="showEditGrouptModal || showEditUsersGroup" @close="showEditGrouptModal = false, showEditUsersGroup = false"></div>

    </div>
</template>

<script>

import EditGroupModal from '@/components/Modal/EditGroupModal'
import EditUsersGroup from '@/components/Modal/EditUsersGroup'
import { useGroupStore } from '@/store/GroupStore'
import { EventBus } from '@/store/EventBus'

export default {
    data () {
        const groupStore = useGroupStore()
        return {
            groupStore: groupStore,
            selected: 'Show active',
            search: '',
            groupRow: [],
            groupUser: [],
            showEditGrouptModal: false,
            showEditUsersGroup: false,
            form: []
        }
    },
    components: {
            EditGroupModal, EditUsersGroup
        },
    methods: {
        showEditUsersGroupMethod(item){
            this.groupUser = item
            this.showEditUsersGroup = !this.showEditUsersGroup
            item.showEditUsersGroup = true
        },
        showEditModalMethod(item){
            this.groupRow = item
            this.showEditGrouptModal = !this.showEditGrouptModal
        },
        archiveGroup(item){
            this.groupStore.archive(item)
        }
    },
    created(){
        this.groupStore.listing()
    }
}
const wlTeamGroupsHandler = function() {
    const groupStore = useGroupStore()
    groupStore.listing()
    EventBus.$off('click', wlTeamGroupsHandler);
}

EventBus.$on('click', wlTeamGroupsHandler);
</script>


<style scoped>
    .team-groups__wrp{
        color: #222222;
        min-width: 200px;
        overflow-x: auto;
        height: auto;
        margin: 10px 30px 0 30px;
        padding-right: 0;
        box-shadow: 0px -11px 39px -15px rgba(34, 60, 80, 0.1);
    }

    table{
        width: 100%;
        min-width: 500px;
        margin-bottom: 240px;
        background-color: #fff;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    }

    th{
        padding: 16px 5px;
        text-align: left;
        border-bottom: 1px solid #F2F3F6;
        color: #606569;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;

    }

    th:first-child,
    th:nth-child(4),
    td:first-child,
    td:nth-child(4){
        padding: 20px;
    }

    th:first-child,
    td:first-child{
        width: 75%;
    }

    th:nth-child(2),
    td:nth-child(2){
        width: 15%;
        cursor: pointer;
    }

    td:nth-child(2) svg{
        padding-left: 5px;
        padding-bottom: 2px;
        cursor: pointer;
    }

    td{
        padding: 20px 5px;
        border-bottom: 1px solid #F2F3F6;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        position: relative;
        color: #222222;
    }

    td:nth-child(3){
        cursor: pointer;
    }


    td:nth-child(4){
        text-align: center;
        cursor: pointer;
    }


    tr:last-child td{
        border-bottom: none;
    }

    @media(max-width:560px){
        .team-groups__wrp{
            margin: 10px 0;
        }
    }

    .bg{
        width: 100vw;
        height: 100vh;
        background-color: #3d3d3d;
        opacity: 0.5;
        z-index:  14;
        position: fixed;
        top: 0;
        left: 0;
    }
</style>