<template>
    <div class="team-members__wrp">
        <table>
            <thead>
                <tr>
                    <th
                    :class="sortedClass('name')"
                    @click="sortBy('t')"
                    >
                        Name
                    </th>
                    <th
                    :class="sortedClass('email')"
                    @click="sortBy('e')"
                    >
                        Email
                    </th>
                    <th
                    :class="sortedClass('rate')"
                    @click="sortBy('br')"
                    >
                        Billable Rate
                    </th>
                    <th
                    :class="sortedClass('role')"
                    @click="sortBy('ur')"
                    >
                        Role
                    </th>
                    <th
                    :class="sortedClass('group')"
                    @click="sortBy('g')"
                    >
                        Group
                    </th>
                    <th>
                    </th>
                </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="item in memberStore.sortedItems"
                        :key="item.uk"
                    >
                        <td>{{ item.t }}</td>
                        <td>{{ item.e }}</td>
                        <td class="team-members__rate" @click="showEditRateModalMethod(item)">
                            <p v-bind:class="{ grey: item.rate == '0.0' }">
                                <input type="text" class="billable-rate" id="item.uk" v-model="item.br" @change="changeRate(item.uk)"/>
                                <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.75 12.25L7.75 7L1.75 1.75" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </p>
                        </td>
                        <td class="team-members__td"  @click="showUserRoleModalMethod(item)">
                            <span>{{ item.role }}</span>
                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.63774 4.10521C3.83781 4.29826 4.16219 4.29826 4.36226 4.10521L7.22392 1.3439C7.54666 1.03248 7.31808 0.5 6.86166 0.5H1.13834C0.681918 0.5 0.453341 1.03248 0.776079 1.3439L3.63774 4.10521Z" fill="black"/>
                                </svg>
                        </td>
                        <td class="team-members__td" @click="showEditGroupMemberMethod(item)">
                            <span>{{ item.gt }}</span>
                                <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.63774 4.10521C3.83781 4.29826 4.16219 4.29826 4.36226 4.10521L7.22392 1.3439C7.54666 1.03248 7.31808 0.5 6.86166 0.5H1.13834C0.681918 0.5 0.453341 1.03248 0.776079 1.3439L3.63774 4.10521Z" fill="black"/>
                                </svg>
                        </td>
                        <td @click="checkOpenMenu(item)" >
                            <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 9.00004C0.333313 8.07957 1.0795 7.33337 1.99998 7.33337C2.92045 7.33337 3.66665 8.07957 3.66665 9.00004C3.66665 9.92052 2.92045 10.6667 1.99998 10.6667C1.0795 10.6667 0.333313 9.92052 0.333313 9.00004Z" fill="#606569"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 2.33329C0.333313 1.41282 1.0795 0.666626 1.99998 0.666626C2.92045 0.666626 3.66665 1.41282 3.66665 2.33329C3.66665 3.25377 2.92045 3.99996 1.99998 3.99996C1.0795 3.99996 0.333313 3.25377 0.333313 2.33329Z" fill="#606569"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.333313 15.6667C0.333313 14.7462 1.0795 14 1.99998 14C2.92045 14 3.66665 14.7462 3.66665 15.6667C3.66665 16.5871 2.92045 17.3333 1.99998 17.3333C1.0795 17.3333 0.333313 16.5871 0.333313 15.6667Z" fill="#606569"/>
                            </svg>
                            <div v-if="item.openMenu" class="team-members__mini-modal" >
                                <!-- p>Transfer ownership</p -->
                                <p @click="deactivateUser(item)"> {{ activateDeactivateTitle(item) }}</p>
                            </div>
                        </td>
                        <td>
                            <EditGroupMember ref="editGroupMember" :userRow="item" :updateMethod="updateTeamMember" v-if="item.showEditGroupMember" @close="item.showEditGroupMember = false"/>
                        </td>
                    </tr>
                    <UserRoleModal ref="userRoleModal" :updateMethod="updateTeamMember" v-if="showUserRoleModal" @close="showUserRoleModal = false"/>
                    <div class="bg" v-if="showUserRoleModal" @close="showUserRoleModal = false"></div>
                    <EditRateModal ref="editRateModal" :updateMethod="updateTeamMember" v-if="showEditRateModal" @close="showEditRateModal = false"/>
                    <div class="bg" v-if="showEditRateModal" @close="showEditRateModal = false"></div>
                </tbody>
        </table>

    </div>
</template>

<script>

import EditRateModal from '@/components/Modal/EditRateModal'
import UserRoleModal from '@/components/Modal/UserRoleModal'
import EditGroupMember from '@/components/Modal/EditGroupMember'
import { useMemberStore } from '@/store/MemberStore';
import { EventBus } from '@/store/EventBus'

export default {
    data () {
        const memberStore = useMemberStore()
        return {
            memberStore: memberStore,
            showEditRateModal: false, 
            showUserRoleModal :false,
            showEditGroupMember: false
        }
    },
    components: {
        EditRateModal, UserRoleModal, EditGroupMember
    },
    created() {
        this.memberStore.listing()
    },
    watch: {
        showEditRateModal(){
            if ( !this.showEditRateModal ) this.$forceUpdate()
        },
        showUserRoleModal(){
            if ( !this.showUserRoleModal ) this.$forceUpdate()
        },
        showEditGroupMember(){
            if ( !this.showEditGroupMember ) this.$forceUpdate()
        }
    },
    methods: {
        activateDeactivateTitle(item){
            return item.a == 0 ? 'Deactivate' : 'Activate'
        },
        deactivateUser(item){
            this.memberStore.remove(item)
            this.$forceUpdate()
        },
        updateTeamMember(){
            this.$forceUpdate()
        },
        showEditGroupMemberMethod(item){
            if ( !item.showEditGroupMember ) {
                this.memberStore.sortedItems.forEach(item => 
                    item.showEditGroupMember = false
                );
                item.showEditGroupMember = !item.showEditGroupMember
            } else {
                this.memberStore.sortedItems.forEach(item => 
                    item.showEditGroupMember = false
                );
            }
            this.$forceUpdate()
        },  
        showUserRoleModalMethod(item) {
            this.showUserRoleModal = !this.showUserRoleModal
            if ( item.showUserRoleModal )
                this.$nextTick(() => {
                    this.$refs.userRoleModal.setMember(item)
                })
        },
        showEditRateModalMethod(item){
            this.showEditRateModal = !this.showEditRateModal
            if ( this.showEditRateModal )
                this.$nextTick(() => {
                    this.$refs.editRateModal.setMember(item)
                })
        },
        sortedClass (key) {
            this.memberStore.sortedClass(key)
        },
        sortBy (key) {
            this.memberStore.sortBy(key)
        },
        checkOpenMenu(i){
            if(i.openMenu == false){
                this.memberStore.sortedItems.forEach(item => 
                    item.openMenu = false
                );
                i.openMenu = true;
                this.$forceUpdate()
            } else {
                this.memberStore.sortedItems.forEach(item => 
                    item.openMenu = false
                );
                this.$forceUpdate()
            }
        }
    }
}

const wlTeamMembersHandler = function() {
    const memberStore = useMemberStore()
    memberStore.listing()
    EventBus.$off('click', wlTeamMembersHandler);
}

EventBus.$on('click', wlTeamMembersHandler);
</script>


<style scoped>
    p {
        margin: 0;
        padding: 0;
    }

    .team-members__wrp{
        color: #222222;
        min-width: 200px;
        overflow-x: auto;
        height: auto;
        margin: 10px 30px 0 30px;
        padding-right: 0;
        box-shadow: 0px -11px 39px -15px rgba(34, 60, 80, 0.1);

    }

    table{
        width: 100%;
        min-width: 900px;
        margin-bottom: 150px;
        background-color: #fff;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    }

    th{
        padding: 16px 5px;
        text-align: left;
        border-bottom: 1px solid #F2F3F6;
        color: #606569;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;

    }

    th:first-child,
    th:nth-child(6),
    td:first-child,
    td:nth-child(6){
        padding: 20px;
    }

    
    th:first-child,
    td:first-child{
        width: 45%;
    }

    th:nth-child(2),
    td:nth-child(2){
        width: 19%;
    }

    td:nth-child(3){
        width: 11%;
        padding: 20px 0;
        cursor: pointer;
    }

    th:nth-child(4),
    td:nth-child(4){
        width: 10%;
        padding-left: 20px;
        cursor: pointer;
    }

    
    th:nth-child(5),
    td:nth-child(5){
        width: 10%;
        padding-left: 20px;
        cursor: pointer;

    }

    td:nth-child(6){
        text-align: center;
        cursor: pointer;

    }

    .team-members__rate p{
        width: 115px;
        height: 40px;
        border: 2px solid #F2F3F6;
        border-radius: 25px;
        padding-left: 20px;
        line-height: 38px;
        box-sizing: border-box;
    }

    .team-members__rate svg{
        padding-left: 30px;
    }

    .team-members__rate span{
        padding-bottom: 3px;
    }

    td svg{
        padding-left: 5px;
    }

    td{
        padding: 20px 5px;
        border-bottom: 1px solid #F2F3F6;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        position: relative;
    }

    tr{
        position: relative;
    }
    
    tr:last-child td{
        border-bottom: none;
    }

    .team-members__td svg{
        padding-bottom: 2px;
    } 

    .team-members__mini-modal{
        height: auto;
        border: 1px solid #F6F7F9;
        padding: 0 15px;
        position: absolute;
        background-color: #fff;
        left: -77px;
        top: 29px;
        z-index: 13;
    }

    .team-members__mini-modal p{
        cursor: pointer;
        margin: 15px 0;
    }

    @media(max-width:560px){
        .team-members__wrp{
            margin: 10px 0;
        }
    }

    .bg{
        width: 100vw;
        height: 100vh;
        background-color: #3d3d3d;
        opacity: 0.5;
        z-index:  19;
        position: fixed;
        top: 0;
        left: 0;
    }

    .grey{
        color: #BABCBF;
    }
</style>