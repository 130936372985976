<template>
    <div class="team-info">
        <div class="team__title">
            <p class="team__main-title">Team</p>
        </div>
        <vue-tabs>
                <v-tab title="Members">
                    <TeamInfo v-bind:item="member"/>
                    <TeamMembers />
                </v-tab>
                <v-tab title="Groups">
                    <TeamInfo v-bind:item="group"/>
                    <TeamGroups />
                </v-tab>
        </vue-tabs>
    </div>
</template>

<script>
    import TeamInfo from '@/components/Team/TeamInfo'
    import TeamMembers from '@/components/Team/TeamMembers'
    import TeamGroups from '@/components/Team/TeamGroups'
    import {VueTabs, VTab} from 'vue-nav-tabs'
    import 'vue-nav-tabs/themes/vue-tabs.css'

    export default {
        data(){
            return {
                member: 'member',
                group : 'group'
            }
        },
        components: {
            TeamInfo,  VueTabs, VTab, TeamMembers, TeamGroups
        }
    }
</script>

<style>
    .team__main-title{
        padding: 0;
        margin: 0;
    }

    .team-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        width: 100%;
        max-width: calc(100vw - 263px);
        transition: all .3s ease;
    }

    .close .team-info{
        max-width: calc(100vw - 66px);
        transition: all .3s ease;
    }

    .team__title{
        margin: 20px 0 0 30px;
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        padding: 15px 20px 10px 20px;
        border-radius: 30px 0 0 0;
        background-color: #fff;
        box-shadow: -31px -26px 25px -13px rgba(34, 60, 80, 0.04);
        display: inline-block;
        z-index: 5;
    }

    .nav-tabs-navigation{
        margin-top: -56px;
        margin-left: 143px;
    }

    .nav-tabs-wrapper{
        height: 56px;
    }

    .vue-tabs{
        width: 100%;
    }

    .vue-tabs .nav-tabs{
        border: none;

    }

    .vue-tabs .nav-tabs > li{
        height: 56px;
        box-shadow: 6px -10px 23px -10px rgba(34, 60, 80, 0.05);
    }

    .vue-tabs .nav-tabs > li > a{
        line-height: 44px;
        border: none;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #222222;
        border-top: 2px solid #fff;
        border-radius: 0;
    }

    .vue-tabs .nav-tabs > li.active > a{
        border: none;
        border-top: 2px solid #323DA3;
        height: 36px;
        margin: 0;
        background: #EBECF6;
        border-radius: 0;
        font-size: 16px;
        font-weight: 600;
        color: #222222;
    }

    .vue-tabs .nav-tabs > li.active > a,
    .vue-tabs .nav-tabs > li.active > a:hover,
    .vue-tabs .nav-tabs > li.active > a:focus{
        height: 34px;
        color: #222222;
        background: #EBECF6;
        border: none;
        border-top: 2px solid #EBECF6;
    }


    div.vue-tabs .nav-tabs > li.active > a:focus{
        color: #222222;
        height: 34px;
        background: #EBECF6;
        border: none;
        border-top: 2px solid #323DA3;
    }

    .vue-tabs .nav-tabs > li.active > a{
        border-top: 2px solid #323DA3;
        height: 34px;
    }
    
    .vue-tabs .nav > li > a:hover,
    .vue-tabs .nav > li > a:focus{
        background: #EBECF6;
        border: none;
        height: 34px;
        border-top: 2px solid #EBECF6;
    }

    .vue-tabs .nav-tabs > li{
        background: #fff;
    }

    .vue-tabs .nav-tabs > li:last-child,
    .vue-tabs .nav-tabs > li:last-child > a{
        border-radius: 0 30px 0 0;
    }

    .vue-tabs .nav-tabs > li:last-child > a{
        padding-right: 25px;
    }

    @media(max-width: 800px) {
        .team-info{
            max-width: calc(100vw - 66px);
        }

        .close .team-info{
            max-width: calc(100vw - 66px);
        }
    }

    @media(max-width:560px){

        .team-info{
            margin: 0 15px;
            padding: 10px 0;
        }
        
        .team__title{
            margin: 0;
        }

        .nav-tabs-navigation{
            margin-left: 113px;
        }

        .team-info{
            max-width: calc(100vw - 96px);
        }

        .close .team-info{
            max-width: calc(100vw - 96px);
        }

    }

    @media(max-width: 410px){
        .team__title{
            background: none;
            box-shadow: none;
        }

        .nav-tabs-navigation{
            margin-top: 0;
            margin-left: 0;
        }

        .vue-tabs .nav-tabs > li:first-child > a{
            padding-left: 25px;
            border-radius: 30px 0 0 0;
        }
    }

    @media(max-width: 308px){
        .vue-tabs .nav-tabs > li > a{
            font-size: 12px;
        }

        .vue-tabs .nav-tabs > li:first-child > a{
            padding-left: 10px;
            border-radius: 0;
        }

        .vue-tabs .nav-tabs > li:last-child > a{
            padding-right: 10px;
            border-radius: 0;
        }

        .vue-tabs .nav-tabs > li:last-child{
            border-radius: 0;
        }
    }



</style>