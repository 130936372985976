<template>
        <div class="edit-users-modal-modal" v-click-outside="onClickOutside">
            <p class="edit-users-modal-modal__p">
                <span v-for="item in checkedNames" :key="item"> {{item}} </span>
            </p>
            <div class="edit-users-modal-modal__wrp">
                <p class="modal__title">
                    Users
                </p>
                <span class="group-member-span" v-for="item in memberStore.sortedItems"
                        :key="item.uk">
                    <input class="modal__input" type="checkbox" :id="item.uk" :value="item.uk" v-model="checkedUks">
                    <label class="modal__label" :id="item.uk" @click="checkMember(item)">{{ item.t }}</label>
                </span>
            </div>
            <svg class="double-slash" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.6667 7.66699L7.66669 13.667" stroke="#BABCBF" stroke-width="2" stroke-linecap="round"/>
                <path d="M13.6667 1.66699L1.66669 13.667" stroke="#BABCBF" stroke-width="2" stroke-linecap="round"/>
            </svg>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import { useGroupStore } from '@/store/GroupStore';
    import { useMemberStore } from '@/store/MemberStore';


    export default {
        props: ['groupRow'],
        data() {
            const groupStore = useGroupStore()
            const memberStore = useMemberStore()
            return {
                groupStore: groupStore,
                memberStore: memberStore,
                checkedNames: [],
                checkedUks: [],
                memberKeys: this.groupRow.m_uks ? this.groupRow.m_uks.split(','): []
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            },
            checkMember(item){
                if ( !this.checkedUks.includes(item.uk) ) {
                    this.checkedUks.push(item.uk)
                    this.checkedNames.push(item.t)
                    this.groupStore.addUserToGroup(this.groupRow.uk, item.uk)
                } else {
                    let index = this.checkedUks.indexOf(item.uk)
                    this.checkedNames.splice(index, 1)
                    this.checkedUks.splice(index, 1)
                    this.groupStore.removeUserFromGroup(this.groupRow.uk, item.uk)
                }
                this.groupRow.mn = this.checkedNames.join(',')
                this.groupRow.m_uks = this.checkedUks.join(',')
            }
        },
        created() {
            this.checkedUks = this.groupRow.m_uks.split(',')
            this.checkedNames = this.groupRow.mn.split(',')
            this.memberStore.listing()
        },
    }
</script>

<style scoped>
    span.group-member-span{
        margin: 5px 0px;
    }
    .edit-users-modal-modal{
        width: 330px;
        height: auto;
        background-color: #fff;
        position: absolute;
        padding: 15px;
        color: #222222;
        box-sizing: border-box;
        z-index: 19;
        right: 0px;
        bottom: -230px;
        border: 1px solid #F6F7F9
    }

    .edit-users-modal-modal__wrp{
        display: flex;
        flex-direction: column;
        border-top: 2px solid #F5F6F8;
        overflow-y: auto;
        max-height: 180px;

    }

    .edit-users-modal-modal__p{
        width: 100%;
        margin: 0;
        padding: 4px 15px;
        height: 36px;
        border-radius: 30px;
        border: 2px solid #EAEEF1;
        margin-bottom: 8px;
        box-sizing: border-box;
        overflow-y: auto;
    }

    .edit-users-modal-modal__p span{
        display: inline-block;
        padding: 1px 5px;
        border: 0.5px solid #eaeaea;
        margin: 1px 3px;
        border-radius: 5px;
    }

    .modal__input{
        display: none;
    }

    .modal__label{
        position: relative;
        padding-left: 30px;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }

    .modal__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: 0px;
        left: 0;
    }

    .modal__input:checked + .modal__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .modal__title{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        padding: 0;
        margin: 0;
        padding-top: 10px;
        color: #BABCBF;
    }

    .double-slash{
        position: absolute;
        right: 3px;
        bottom: 3px;
        cursor: pointer;
    }

    @media(max-width: 450px) {
        .edit-users-modal-modal{
            width: 70vw;
        }
    }

</style>