<template>
        <div class="add-member-modal" v-click-outside="onClickOutside">
            <h3 class="add-member-modal--title">
                Add member by email
            </h3>
            <form class="add-member-modal__form">
                <button href="" class="close_X" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33301 14.6667L7.99967 7.99999M14.6663 1.33333L7.99967 7.99999M7.99967 7.99999L14.6663 14.6667M7.99967 7.99999L1.33301 1.33333" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <!-- v-selectize :options="options" @search="text = $event" v-model="selected" :create-item="maybeCreate()" multiple placeholder="Pick some people..." keyBy="email" label="name" :keys="['name', 'email']">
                        <template slot="item" slot-scope="{item}">{{item.name}} {{item.email}} </template>
                        <template slot="option" slot-scope="{option}">
                            <label>{{ option.name }}</label>
                            <span>{{ option.email }}</span>
                        </template>
                </v-selectize -->
                <input type="email" v-model="form.email" class="team__input team__search"/>
                <!--p class="add-member-modal__txt">
                    Add up to 5 members at once by separating emails with a comma
                </p-->
                <p class="add-member-modal__input-wrp">
                    <input class="add-member-modal__input" type="checkbox" name="" v-model="form.inviteEmail" id="sent-invite">
                    <label class="add-member-modal__label" for="sent-invite">Send an invite email</label>
                </p>
                <img src="../../assets/img/reCapc.jpg" width="300" alt="">
                <div class="buttons">
                    <button @click="addMember" class="add-member-modal__add">
                        Add
                    </button>
                    <button @click="$emit('close')">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    //import VSelectize from '@isneezy/vue-selectize'
    import { useMemberStore } from '@/store/MemberStore';

    export default {
        name: 'email-contacts',
        data () {
            const memberStore = useMemberStore()
            return {
                memberStore: memberStore,
                form: { email: '', inviteEmail: 0},
                options: [
                ],
                selected: null
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            },
            addMember(){
                this.memberStore.insert(this.form).then(response => {
                    if ( response.data.result=='ok')
                        this.$emit('close')
                    else
                        this.errors.push(response.data.message);
                })
                .catch(e => {
                    this.errors.push(e)
                })
            },
            /*maybeCreate () {
                return this.createContact
            },
            createContact (email) {
                if (this.options.length < 5){
                    const contact = {email}
                    this.options.push(contact)
                    return contact
                } else {
                    alert('Only 5!');
                }
            }*/
        },
        components: { /*VSelectize*/ }
    };
</script>

<style scoped>
    .add-member-modal__form input[type="email"]{
        margin-top: 5px;
        border-radius: 30px;
        height: 42px;
        border: 2px solid #F2F3F6;
        font-family: Montserrat;
        outline: none;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
    }
    .add-member-modal{
        width: 549px;
        height: 380px;
        background-color: #fff;
        position: fixed;
        top: 10%;
        padding: 20px;
        color: #222222;
        box-sizing: border-box;
        margin-bottom: 50px;
        z-index: 20;
        right: calc( 50vw - 269px);
        margin-left: -273px;
    }

    .add-member-modal--title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 25px;
        border-bottom: 2px solid #F2F3F6;
    }

    .add-member-modal__name{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .add-member-modal__item{
        display: flex;
        flex-direction: row;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .buttons{
        display: flex;
        flex-direction: row-reverse;
    }

    .buttons button{
        margin-left: 10px;
        margin-top: 10px;
        width: 107px;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .buttons .add-member-modal__add{
        background-color: #9CEDFF;
    }

    @media(max-width:800px){
        .bg{
            left: 0;
        }
    }

    
    @media(max-width:600px){
        .add-member-modal{
            width: 100vw;
            margin-left: -75px;
            right: 0;
            box-sizing: border-box;
        }
    }

    .close_X{
        position: absolute;
        right: 19px;
        top: 23px;
    }

    .add-member-modal__input{
        display: none;
    }

    .add-member-modal__label{
        position: relative;
    }

    .add-member-modal__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: 0px;
        left: -30px;
    }

    .add-member-modal__input:checked ~ .add-member-modal__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .add-member-modal__txt{
        margin: 0;
        padding: 0;
        font-size: 10px;
        padding-left: 15px;
    }

    .add-member-modal__input-wrp{
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
        margin-top: 20px;
        padding-left: 30px;
    }

    img{
        margin: 10px 0;
    }

</style>