<template>
  <div>
    <TeamWrp />
  </div>
</template>

<script>
    import TeamWrp from '@/components/Team/TeamWrp'


    export default {
        components: {
          TeamWrp,
        }
    }
</script>

<style scoped>

</style>